<template>
  <b-modal
    id="show-enablement-modal"
    title="Colour by Enablement"
    size="lg"
    :ok-title="loading ? 'Colouring by enablement...' : 'Colour by enablement'"
    :ok-disabled="loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @hidden="onHide"
  >
    <b-form-group
      v-if="!loading"
      label="Colour by linked enablers based on"
      label-for="config-select"
    >
      <b-form-select
        id="config-select"
        v-model="selectedConfig"
        :disabled="loading"
        :select-size="2"
      >
        <b-form-select-option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.label }}
        </b-form-select-option>
      </b-form-select>
      <div>
        <label for="ed">Effective from Date</label>
        <b-form-datepicker id="ed" size="sm" locale="en" class="mb-2" v-model="effectiveDate"></b-form-datepicker>
      </div>
    </b-form-group>
    <div v-else class="w-100 justify-content-center">
      <div class="w-100 d-inline-flex justify-content-center mb-1">
        <atom-spinner />
      </div>
      <div class="font-medium-2 text-primary text-center">
        Colouring nodes based on {{ options.find(o => o.value === selectedConfig).label }}
      </div>
      <div class="font-medium-2 text-primary text-center">
        This may take some time...
      </div>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="outline-secondary" @click="onHide">
        Close
      </b-button>

      <b-button variant="success" :disabled="loading" @click="onSubmit">
        <span v-if="loading">
          <b-spinner small type="grow" />
          Colouring nodes...
        </span>
        <span v-else>
          Colour by enablement
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'ShowEnablementModal',
  components: { AtomSpinner },
  data: () => ({
    selectedConfig: 'status',
    effectiveDate: null,
    loading: false,
    options: [
      { value: 'status', label: 'Operational Status' },
      { value: 'trl', label: 'Technology Readiness Level' },
    ],
  }),
  computed: {
    ...mapState({
      selected_bt: state => state.behaviours.selectedBehaviourTree,
    }),
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.loading = true
      const params = {
        model: this.$store.state.model.id,
        config: this.selectedConfig,
        date: this.effectiveDate,
      }
      coreService.get(`/v1/legacy/behaviour/${this.selected_bt.id}/enablement`, { params })
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Coloured nodes by enablement',
              text: '',
              icon: 'CheckIcon',
              variant: 'info',
            },
          })
          this.$store.dispatch('domainModel/selectTRLDate', this.effectiveDate)
          this.$emit('enablement', { config: this.selectedConfig, data, effectiveDate: this.effectiveDate})
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to colour nodes by enablement',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { this.onHide(evt) })
    },
    onHide(evt) {
      evt.preventDefault()
      this.loading = false
      this.selectedConfig = 'status'
      this.$bvModal.hide('show-enablement-modal')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.modal-footer-behaviour {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
