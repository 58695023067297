<template>
  <b-modal
    id="generate-bt-requirements-modal"
    :title="`Generate functional requirements for ${component.name}`"
    size="xl"
    class="p-0"
    @show="onShow"
    @ok="generateFunctionalRequirements()"
  >
    <div>
      <GenerateBTRequirementForm
        v-model="fields"
        :component="component"
        type="functional"
      />
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button :disabled="!fields.specification" variant="primary" @click="ok()">
        Generate
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import GenerateBTRequirementForm from '@/components/Behaviours/Modals/GenerateBTRequirementForm.vue'
import { mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'GenerateRequirementsFn',
  components: {
    GenerateBTRequirementForm,
  },
  data() {
    return {
      fields: {
        type: 'functional',
        specification: null,
        dest_target: null,
        selected_performers: [],
        selected_objectives: [],
        selected_standards: [],
        selected_enablers: [],
        selected_beneficiaries: [],
        allowed_performers: [],
        allowed_enablers: [],
        direction: 'after',
      },
    }
  },
  computed: {
    ...mapState({
      selectedBT: state => state.behaviours.selectedBehaviourTree,
    }),
    component() {
      return this.selectedBT
    },
  },
  watch: {
    default(newVal) { this.value = newVal },
    selected_entity(newVal) {
      const t = this.fields
      t.selected_performers = []
      t.allowed_performers = []
      t.selected_beneficiaries = []
      t.selected_enablers = []
      t.allowed_enablers = []
      t.selected_objectives = []
      t.selected_standards = []
      t.selected_allocations = []
    },
  },
  methods: {
    onShow() {
      this.$store.dispatch('domainModel/getComponents')
    },
    generateFunctionalRequirements() {
      coreService.post(`/v1/legacy/behaviour/generate_frs/${this.selectedBT.id}`, {
        model: this.$store.state.model.id,
        function: '',
        dest_spec: this.fields.specification,
        dest_target: this.fields.dest_target,
        direction: this.fields.direction,
        type: 'shall',
        performers: [],
        default_enablers: this.fields.selected_enablers,
        beneficiaries: [],
        objectives: [],
        standards: [],
        allowed_performers: [],
        allowed_enablers: this.fields.allowed_enablers,
      })
        .then(({ data }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Generated ${data.requirements_generated} requirement${data.requirements_generated > 1 ? 's' : ''}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to generate requirements',
              icon: 'XIcon',
              text: `${error}`,
              variant: 'danger',
            },
          })
        })
        .finally(() => { this.$bvModal.hide('generate-bt-requirements-modal') })
    },
  },
}
</script>
