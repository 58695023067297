<template>
  <b-modal
    id="view-requirement-modal"
    :title="`View Behaviours for Selected Requirement`"
    size="lg"
    class="p-0"
    lazy
    no-close-on-esc
    no-close-on-backdrop
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <div>
      <h4>
        Select Requirement
      </h4>
      <h6 class="mt-2 text-primary">
        1. Select Specification
      </h6>
      <SpecificationPicker v-model="targetSpecification" />

      <h6 class="mt-2 text-primary">
        2. Select Requirement
      </h6>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area-idea-entities"
        style="max-height: 20rem"
      >
        <b-table
          responsive
          striped
          hover
          selectable
          show-empty
          select-mode="single"
          class="position-relative"
          :fields="requirementTableFields"
          :items="requirementsList"
          @row-selected="onRowSelected"
        />
      </vue-perfect-scrollbar>
      <hr>
      <h5 v-if="targetRequirement" class="text-center mt-1">
        Highlight behaviours for:
        <span class="text-primary">{{ targetRequirement.display_id }}</span>
      </h5>
    </div>

    <!-- Buttons -->
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        :disabled="loading"
        variant="outline-secondary"
        @click="cancel"
      >
        Discard
      </b-button>
      <b-button variant="success" :disabled="loading || !targetRequirement" @click="onSubmit">
        Select Requirement
      </b-button>
    </template>
    <!-- ./Buttons -->
  </b-modal>
</template>

<script>
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import { computed, ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ViewRequirementModal',
  props: {
    bt: {
      type: String,
      required: true,
    },
  },
  components: {
    VuePerfectScrollbar,
    SpecificationPicker,
  },
  setup(props, context) {
    const loading = ref(false)
    const perfectScrollbarSettings = { maxScrollbarLength: 60 }
    const requirementTableFields = [
      { key: 'section', label: 'Section' },
      { key: 'display_id', label: 'Display ID' },
      { key: 'priority', label: 'Priority' },
    ]

    const sourceRequirement = computed(() => store.state.requirements.selected_requirement)
    const targetSpecification = ref(null)
    const targetRequirement = ref(null)
    const relationToTarget = ref('after')

    const requirementsList = ref([])
    watch(targetSpecification, val => {
      requirementsList.value = []
      if (targetSpecification.value !== null) {
        coreService
          .get(`/v1/legacy/specifications/${targetSpecification.value.id}/requirements`)
          .then(({ data }) => {
            Object.entries(data).forEach(entry => {
              requirementsList.value.push(entry[1])
            })
          })
      }
    })

    const onShow = () => {
      targetSpecification.value = null
      targetRequirement.value = null
      relationToTarget.value = 'after'
    }

    const onRowSelected = rows => {
      if (rows[0] && rows[0].id) {
        // eslint-disable-next-line prefer-destructuring
        targetRequirement.value = rows[0]
      }
    }

    const onSubmit = () => {
      coreService.get(`/v1/legacy/behaviour/get_behaviours_for_requirement/${props.bt}/${targetRequirement.value.id}`)
        .then(({ data }) => {
          context.root.$bvModal.hide('view-requirement-modal')
          context.emit('requirementSelected', data)
        })
    }

    return {
      loading,
      perfectScrollbarSettings,
      requirementTableFields,
      requirementsList,

      sourceRequirement,
      targetSpecification,
      targetRequirement,
      relationToTarget,

      onShow,
      onRowSelected,
      onSubmit,
    }
  },
}
</script>
